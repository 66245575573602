<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :key="resetTab" :list="statusTab" :filter.sync="filter" :status="basicStatusTab" :breadcrumbs="breadcrumbs" :hiddenTab="!$helpers.hasSomePermission(['view_summary'])" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_summary'])">
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$helpers.hasSomePermission(['add_summary'])" class="ml-2" color="primary" v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-box </v-icon>Crear proceso<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="currentReport='Informe de reversiones', dialogCreate=true" :ripple="false">
              <v-list-item-title class="body-1 fontDraw--text">Informe de reversiones</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="currentReport='Informe de bajas', dialogCreate=true" :ripple="false">
              <v-list-item-title class="body-1 fontDraw--text">Informe de bajas</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="currentReport='Informe de bajas de boletas', dialogCreate=true" :ripple="false">
              <v-list-item-title class="body-1 fontDraw--text">Informe de bajas de boletas</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:secondary v-if="$helpers.hasSomePermission(['view_summary'])">
        <template>
          <v-btn @click="getList" outlined :disabled="!count">
            <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
          </v-btn>
          <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item class="px-3" @click="createDownloadList" :disabled="!count" :ripple="false">
                <v-list-item-title class="body-1 fontDraw--text">Descargar listado</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-query-builder class="ml-2" :query-map="filters" model="documents" :active.sync="activeFilter" />
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0 mt-25 pb-10">
      <documents-list :show-create="true" @activeBtnFilter="activeFilter = true" :selectedDocuments.sync="selectedDocuments" :toggleDropdown.sync="toggleDropdown" :key="reset" :headerList="headers" :pageSize="pageSize" @getList="getList"  />
    </v-col>
    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Descarga de documentos emitidos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <!-- <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="fontBody--text body-1 font-weight-bold">Resumen de la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="fontBody--text body-1 font-weight-semibold">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="pt-2">
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-checkbox class="ma-0 vcheckbox" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" :disabled="['XLSX', 'CSV', 'XML'].includes(file) && count >= 1000000" dense hide-details />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- alert -->
          <v-alert class="inf-alert-blue primary--text rounded-md mt-2" :height="58" dense text>
            <div class="d-flex pa-0 my-1">
              <v-icon color="primary" size="22">mdi-information-outline</v-icon>
              <div class="ml-4">
                <span class="body-2" style="line-height: 14px;">Esta acción
                  <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. Recibirás un
                  <span class="font-weight-bold"> correo electrónico con el enlace de descarga.</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="primary" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->

    <!-- email -->
    <v-dialog v-model="sendEmailDialog" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Enviar documento por email</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="sendEmailDialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-col class="pa-0">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="fontBody--text body-1" style="min-width: 60px;">Para</span>
                  <v-combobox class="a-combobox" v-model="messages.recipient" hide-details :items="sendEmailList" item-value="id" item-text="name" chips small-chips multiple outlined required hide-selected hide-no-data single-line dense autocomplete="off">
                    <template v-slot:selection="data">
                      <v-chip class="pr-2" small v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">{{ data.item.name }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="fontBody--text body-1" style="min-width: 60px;">Asunto</span>
                  <v-text-field v-model="messages.subject" outlined required single-line dense maxlength="60" persistent-hint  hide-details />
                </div>
                <v-col class="pa-0 mt-2">
                  <span class="fontBody--text body-1 mr-5">Archivos adjuntos</span>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="pdf" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>*.pdf
                  </v-chip>
                  <v-chip class="ma-1 pl-1 pr-2" color="defaultGrey" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="xml" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>*.xml
                  </v-chip>
                </v-col>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="pa-0 pb-4 mx-auto bgPanel overflow-y-auto text-center" style="max-height: 450px;">
                <v-alert class="inf-alert primary--text" :height="50" dense text outlined color="#BED2F0" tile>
                  <div class="d-flex px-2 py-0">
                    <v-icon color="primary" size="26">mdi-information</v-icon>
                    <div class="ml-4 py-1">
                      <span class="body-1">Esta es una vista previa del documento N° {{ (selectedDocuments[0] || []).number }}.</span>
                    </div>
                  </div>
                </v-alert>
                <v-alert border="top" class="pa-0 mt-6 mx-auto" color="var(--light-blue-primary)" colored-border elevation="0" :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`" />
                  <v-sheet class="mx-auto" :width="$vuetify.breakpoint.width < 1270 ? 467 : 566" style="border-radius: 10px; margin-top: -17px;" outlined>
                    <reminder-documents-email v-if="selectedDocuments" :instance="selectedDocuments[0]" :activeDocument="activeDocument((selectedDocuments[0] || []).document_type || {})" :message.sync="messages.text" />
                  </v-sheet>
              </v-card-text>
              <v-divider />
              <v-card-actions class="py-4 px-5">
                <v-spacer />
                <v-btn @click="sendEmailDialog=false" outlined>Cancelar</v-btn>
                <v-btn @click="createMail" :loading="$store.state.messages.createLoader" color="primary">Enviar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end email -->

    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" :width="610" persistent no-click-animation>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Crear nuevo proceso</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreate=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0 py-4">
          <v-row class="pt-2 pb-2" align="center" no-gutters>
            <v-col cols="3" class="px-5">
              <img :src="require(`@/assets/backgrounds/modal-document-create-fl.svg`)" :height="158" />
            </v-col>
            <v-col class="pr-5 pl-16">
               <v-list class="v-list-form pa-0 transparent">
                <v-list-item class="py-0">
                  <v-list-item-title>Tipo de documento</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">{{currentReport}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2">
                  <v-list-item-title>Razón social</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">{{$store?.state?.auth?.account?.name || ''}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-0">
                  <v-list-item-title>Fecha</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="$v.reversalsReport.extra_data.periodo.$model" :error="$v.reversalsReport.extra_data.periodo.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                      </template>
                      <v-date-picker v-model="$v.reversalsReport.extra_data.periodo.$model" @input="dateMenu=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="270" color="primary">
                        <v-row class="mb-2 px-6" align="center" justify="end">
                          <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { reversalsReport.extra_data.periodo=null; dateMenu=false }">Limpiar</v-btn>
                          <v-spacer />
                          <v-btn @click="() => { $v.reversalsReport.extra_data.periodo.$model=today; dateMenu=false }" color="primary">Hoy</v-btn>
                        </v-row>
                      </v-date-picker>
                    </v-menu>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-divider class="my-5" />
                <v-list-item class="py-0">
                  <v-list-item-title>Archivo</v-list-item-title>
                  <v-list-item-subtitle class="ml-n13">
                    <v-file-input v-model="$v.reversalsReport.doc_txt.$model" :error="$v.reversalsReport.doc_txt.$error" ref="file" type="file" accept=".txt" prepend="mdi-paper-clip" hide-details background-color="transparent" placeholder="No se eligió ningún archivo" solo flat chips dense />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="dialogCreate=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createProcess" color="primary">Iniciar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { groupBy } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import MiniHeader from '@/components/commons/MiniHeader'
import { documentsFilters } from '@/collections/summariesQueryBuilderFilters'
import DocumentTypes from '@/collections/summariesTypes'
import documentStatuses from '@/collections/summariesStatuses'
import DocumentsList from '@/modules/summaries/components/SummariesList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
import numeral from 'numeral'
import moment from 'moment'

export default {
  components: {
    DocumentsList,
    VQueryBuilder,
    MiniHeader,
    ReminderDocumentsEmail
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    resetTab: 0,
    dateMenu: false,
    currentReport: null,
    reversalsReport: {
      type: '',
      test: true,
      numering: false,
      extra_data: {
        periodo: null,
        razon_social: ''
      },
      doc_txt: null
    },
    dialogCreate: false,
    pageSize: null,
    headerList: [],
    activeFilter: false,
    reset: 0,
    toggleDropdown: null,
    sendEmailList: [
      {
        name: 'Mail de emisión (intercambio)',
        id: 'taxpayer'
      },
      {
        name: 'Mail de contactos',
        id: 'customer'
      }
    ],
    selectedDocuments: [],
    sendEmailDialog: false,
    scroll: 0,
    filter: 0,
    dialogExport: false,
    filters: documentsFilters,
    documentTypes: DocumentTypes,
    documentStatuses,
    loading: false,
    types: [],
    messages: {
      text: '',
      recipient: '',
      subject: '',
      type: ['pdf', 'xml']
    },
    status: [
      {
        title: 'Procesando',
        icon: 'mdi-sync',
        countries: ['CL', 'PE']
      },
      {
        title: 'Aceptado',
        icon: 'mdi-check',
        countries: ['CL', 'PE']
      },
      {
        title: 'Pendiente',
        icon: 'mdi-clock',
        countries: ['PE']
      },
      {
        title: 'Rechazado',
        icon: 'mdi-close',
        countries: ['CL', 'PE']
      },
      {
        title: 'Anulado',
        icon: 'mdi-file-remove',
        countries: ['CL', 'PE']
      }
    ],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Resúmenes'
        }
      ]
    },
    quantitySelectedDocumentTypeForSendEmail: '',
    fileFormat: ['XLSX', 'CSV', 'XML'], // 'PDF',
    headers: [
      { text: 'Tipo de resumen', value: 'type', sortable: true, show: true, disabled: false, label: 'Tipo de documento', fixed: true },
      { text: 'Resumen ID', value: 'serial', align: 'end', sortable: false, show: true, disabled: false, label: 'Serie', fixed: true },
      { text: 'Fecha de ref.', value: 'reference_date', align: 'end', sortable: true, show: true, disabled: false, label: 'Fecha del documento', fixed: true },
      { text: 'Fecha del doc.', value: 'date', align: 'end', sortable: true, show: true, disabled: false, label: 'Fecha del documento', fixed: true },
      { text: 'Fecha de crea.', value: 'created', align: 'end', sortable: true, show: false, disabled: false, label: 'Fecha de creación', fixed: false },
      { text: 'Estado', value: 'status', show: true, disabled: false, label: 'Estado', fixed: true, width: 160 },
      { text: '', value: 'actions', align: 'end', show: true}
    ]
  }),
  computed: {
    ...mapState({
      count: state => state.summaries.summariesList.length
    }),
    statusTab () {
      const list = this.documentStatuses?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
      return Object.entries(groupBy(list, 'summary')).map(([key, value]) => {
        return { key, count: value.length, children: value }
      })
    },
    basicStatusTab () {
      return this.status.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
    },
    activeDocument () {
      return (id) => {
        return DocumentTypes.find(d => d.id === id)
      }
    },
    checkPermissionDTE () {
      if (!!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return !ownGroupPermissions.filter(({permission}) => permission === 'view_summary').length
        
      }
      return false
    },
    enabledCreateDTE () {
      if (!!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return ownGroupPermissions.filter(({permission}) => permission === 'add_document').length > 0
      }
      return true
    },
    currentParams () {
        let params = JSON.parse(JSON.stringify(this.$route.params))
        delete params.id
        return params
      }
  },
  watch: {
    pageSize: {
      handler (val) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (val && currentQuery.page) {
          delete currentQuery.page
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: currentQuery }).catch(() => {})
        } else if (Object.keys(currentQuery)?.length) this.getList()
      },
      inmediate: true
    },
    headers: {
      handler (val) {
        this.headerList = val
      },
      inmediate: true
    },
    $route () {
      if (!this.$route.query?.status) {
        this.filter = 0
        this.resetTab += 1
      }

      // if (!Object.keys(val?.query)?.length) this.$store.commit('documents/SET_LIST', { response: {}, resource: 'documents' })
    },
    filter: {
      handler (val) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (val > 0) {
          delete currentQuery.page
          const list = this.documentStatuses?.filter(({countries, isAX}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country) && isAX)
          let query = list.filter((item) => item.summary === this.basicStatusTab[val - 1].title).map((item) => item.id) // .toString()
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: {...currentQuery, status: query} }).catch(() => {})
        } else {
          delete currentQuery.status
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: currentQuery }).catch(() => {})
        }
      },
      inmediate: false
    },
    selectedDocuments (val) {
      this.messages.subject = `Envía ${this.activeDocument(val[0]?.document_type)?.name ?? ''} N° ${val[0]?.number}`
      this.messages.text = `Estimado cliente, ${val[0]?.customer?.name ?? ''},\n\n`
      this.messages.text += `Adjunto ${this.activeDocument(val[0]?.document_type)?.name ?? ''} N° ${val[0]?.number}, `
      this.messages.text += `correspondiente al ${moment(val[0]?.date).format('DD MMM YYYY')} `
      this.messages.text += `por un monto de ${numeral(val[0]?.total).format('0,0')} ${val[0]?.exchange?.currency_from ?? val[0]?.exchange?.currency_to ?? this.activeDocument(val[0]?.document_type)?.currencies[0] ?? ''} \n\nAtentamente ${this.$store.state?.auth?.account?.name}`
    }
  },
  created () {
    if (this.$route.query.status) {
      this.statusTab.forEach((item, index) => {
        if (item.children.filter(({isAX}) => isAX).map(({id}) => id).toString() === this.$route.query.status.toString()) {
          this.filter = index + 1
        }
      })
    }
    if ((Object.keys(this.$route?.query)?.length)) {
      this.getList()
    }
    this.setFilterDocumentType()
    this.filters = this.filters?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))

    if (this.$route.query.status__code__in) {
      const query = this.$route.query.status__code__in.split(',')[0]
      this.statusTab.forEach((item) => {
        const statusAux = item.children.map((child) => child.code.toString())[0]
        if (query === statusAux) {
          const index = this.status.findIndex(({title}) => title === item.key)
          this.filter = index !== -1 ? index < `${this.$store.state?.auth?.account?.country === 'CL' ? 3 : 5}` ? index + 1 : index : 0
        }
      })
    }
    if (this.$route.query.posdocument__pos__branch__id) {
      this.breadcrumbs.children.push({
        text1: this.$route.query.name,
        route1: { name: 'BranchRetrieveUpdate', params: { id: this.$route.query.posdocument__pos__branch__id, ...this.$route.params } }
      })
    }

    if (this.$route.query.posdocument__pos__id) {
      this.breadcrumbs.children.push({
        text1: this.$route.query.name,
        route1: { name: 'PosRetrieveUpdate', params: { id: this.$route.query.posdocument__pos__branch__id, ...this.$route.params } }
      })
    }

    if (this.$route.query.batchdocument__batch) {
      this.breadcrumbs.children = [
        { text: 'Emisión por lotes', route: { name: 'DocumentsBatchRetrieve', params: { id: this.$route.query.batchdocument__batch, ...this.$route.params }} },
        { text1: this.$route.query.batchdocument__batch },
        { text2: 'Documentos'}
      ]
    }

    if (this.$route.query.customer) {
      this.breadcrumbs.children = [
        { text: 'Clientes', route: { name: 'ContactRetrieveUpdate', params: { id: this.$route.query.customer, ...this.$route.params }} },
        { text1: 'Documentos'}
      ]
    }
  },
  methods: {
    getFileFormat (val = null) {
      if (!this.types?.find((s) => s === val)) this.types.push(val)
      else {
        this.types?.splice(this.types?.indexOf(val), 1)
        this.types = [...this.types]
      }
    },
    remove (item) {
      const index = this.messages.recipient.indexOf(item)
      if (index >= 0) {
        this.messages.recipient.splice(index, 1)
      }
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      if (!this.checkPermissionDTE && Object.keys(this.$route?.query)?.length > 0) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (currentQuery?.ref) delete currentQuery.ref
        Object.keys(currentQuery).forEach((key) => {
          if (['posdocument__pos__branch__id', 'posdocument__pos__id', 'batchdocument__batch'].includes(key)) delete currentQuery.name
        })
        this.$store.dispatch('summaries/LIST', {
          resource: 'summaries',
          query: { ...currentQuery, ...(this.pageSize && { page_size: this.pageSize }) }
        })
          .catch((error) => {
            this.hasPermission = error.toString().search('403') !== -1
          })
          .finally(() => {
            this.reset += 1
          })
      } else {
        this.$store.commit('summaries/SET_LIST', { response: {}, resource: 'summaries' })
      }
    },
    setFilterDocumentType () {
      const currentDocumentType = this.documentTypes.filter(({country}) => country === this.$store.getters['base/currentCountry'])
      const index = this.filters.findIndex(({id}) => id === 'type')
      if (currentDocumentType.length) {
         this.filters[index].choices = currentDocumentType.map(({name, id}) => {
          return {
            label: name,
            value: id
          }
        })

        // if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        //   /* eslint-disable */
        //   const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        //   this.filters[index].choices = this.filters[index].choices.filter((doc) => ownGroupPermissions.some((item) => item.permission === 'view_summary' && item.document_type === doc.value))
        // }
      }
    },
    createExport () {
      this.dialogExport = false
      let currentQuery = this.deleteQueries()
      this.$store.dispatch('accounts/LIST', {
        resource: 'exports/documents',
        query: {
          ...currentQuery,
          extension: this.types.toString(),
          ...(((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length > 0 && this.selectedDocuments.length <= 20)) && !['all', 'filtered'].includes(this.toggleDropdown)) && { id__in: this.selectedDocuments.map(({id}) => id).toString()})
        },
        loader: false
      })
      .then(() => {
        this.$dialog.message.info('Se ha iniciado el proceso de descarga y se le notificará via mail, una vez que haya finalizado', { timeout: 10000 })
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
      })
      .finally(() => {
        this.types = []
      })
    },
    createMail () {
      const type = this.messages.type.length === 2 ? 'all' : this.messages.type.toString()
      this.$store.dispatch('messages/CREATE', {
        resource: 'bulk_messages_filter',
        query: {
          ...((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length <= 20 && !['all', 'filtered'].includes(this.toggleDropdown))) && { id__in: this.selectedDocuments.map(({id}) => id).toString()}),
          ...this.$route.query
        },
        payload: {
          ...(this.messages.recipient.find(({id}) => id === 'taxpayer') && {taxpayer: true}),
          ...(this.messages.recipient.find(({id}) => id === 'customer') && {customer: true}),
          ...(type.length && {type})
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de documentos por email se realizó con éxito')
        this.messages.recipient = ''
        this.messages.type = []
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.sendEmailDialog = false
      })
    },
    deleteQueries () {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      if (currentQuery.name) delete currentQuery.name
      return currentQuery
    },
    createProcess () {
      this.reversalsReport.file = this.file
      this.$v.reversalsReport.$touch()
      if (this.$v.reversalsReport.$invalid) {
        return false
      }
      const summariesType = DocumentTypes.find(({ name }) => name === this.currentReport)?.id
      console.log(summariesType, this.currentReport)
      const formDataDocTxt = new FormData()
      formDataDocTxt.append('file', this.reversalsReport.doc_txt, this.reversalsReport.doc_txt.name)
      const combinedFormData = new FormData()
      combinedFormData.append('object_data', JSON.stringify({
        type: summariesType,
        test: this.$route.path.startsWith('/test'),
        numbering: false,
        extra_data: {
          periodo: this.reversalsReport.extra_data.periodo,
          razon_social: this.$store?.state?.auth?.account?.name
        }
      }))
      combinedFormData.append('doc_txt', this.reversalsReport.doc_txt, this.reversalsReport.doc_txt.name)

      this.$store.dispatch('documents/CREATE', {
        resource: 'generation/upload',
        payload: combinedFormData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.$dialog.message.info('El proceso se ha creado con éxito')
        this.$router.replace({ name: 'SummariesRetrieve', params: {id: response.data.id, ...this.currentParams} })
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.dialogCreate = false
        this.reversalsReport = {
          type: '',
          test: true,
          numering: false,
          extra_data: {
            periodo: null,
            razon_social: ''
          },
          doc_txt: null
        }
        this.$v.reversalsReport.$reset()
      })
    },
    createDownloadList () {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.page) delete currentQuery.page
      if (currentQuery.pageSize) delete currentQuery.pageSize
      this.$store.dispatch('accounts/LIST', {
        resource: 'summaries',
        query: {...currentQuery, ...(!this.$route.query?.ordering && { ordering: ['-date', '-created']}), ...{ format: 'CSV' }},
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data], {type: "application/csv"})
        saveAs(blob, 'resumenes.csv')
        this.$dialog.message.info('La descarga se ha realizado con éxito.')
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
      })
      .finally(() => {
        this.reset += 1 
      })
    },
  },
  validations () {
    const reversalsReport = {
      doc_txt: {
        required
      },
      extra_data: {
        periodo: {
          required
        }
      }
    }
    return {
      reversalsReport
    }
  }
}
</script>
